window.addEventListener('DOMContentLoaded', () => {
  jQuery(function($){

    // GSAP プラグインを定義
    gsap.registerPlugin(ScrollTrigger);

    const scrollXArea = document.querySelector('.fn-scroll-x-area');
    if (scrollXArea) {
      const scrollXContainer  = document.querySelector('.fn-scroll-x-container');
      const scrollXItems = document.querySelectorAll('.fn-scroll-x-item');
      const num = scrollXItems.length;
      let scrollXGsap;
  
      function setupAnimation() {
        // 横幅を指定
        gsap.set(scrollXContainer, { width: num * 100 + '%' });
        gsap.set(scrollXItems, { width: 100 / num + '%' });
        scrollXGsap = gsap.to(scrollXItems, {
          xPercent: -100 * ( num - 1 ), //x方向に移動させる
          ease: 'none',
          scrollTrigger: {
            trigger: scrollXArea, //トリガー
            start: 'top top', //開始位置
            end: '+=2500', //終了位置
            pin: true, //ピン留め
            scrub: true, //スクロール量に応じて動かす
          }
        });
      };

      function stopAnimation() {
        if (scrollXGsap) {
          gsap.set(scrollXContainer, { width: 'auto' });
          gsap.set(scrollXItems, { width: 'auto', xPercent: 0 });
          const trigger = scrollXGsap.scrollTrigger;
          trigger.kill();
          scrollXGsap = null;
        }
      }

      function handleResize() {
        if (window.innerWidth > 600) {
          if (!scrollXGsap) {
            setupAnimation();
          }
        } else {
          stopAnimation();
        }
      }

      if (window.innerWidth > 600) {
        setupAnimation();
      }

      window.addEventListener('resize', handleResize);
    }

    const inView = document.querySelectorAll('.fn-inview');

    if (inView && inView.length > 0) {
      // 要素を初期状態で非表示にする
      inView.forEach((el) => {
        ScrollTrigger.create({
          trigger: el,
          start: 'top 90%',
          onEnter: () => {
            el.classList.add('is-active');
          },
        });
      });
    }

    const banner = document.querySelector('.fn-banner');
    const bannerArea = document.querySelector('.fn-banner-area');
    if (banner && bannerArea) {
    //   ScrollTrigger.create({
    //     trigger: bannerArea,
    //     start: 'top center',
    //     end: 'bottom bottom',
    //     onUpdate: (self) => {
    //       if (self.progress === 0 || self.progress === 1) {
    //         banner.classList.remove('is-active');
    //       } else {
            banner.classList.add('is-active');
    //       }
    //     }
    //   });
    }
  
    // Sliders
    const sliderFv1 = $('.fn-slider-fv-1');
    const sliderFv2 = $('.fn-slider-fv-2');
    if (sliderFv1[0] && sliderFv2[0]) {
      sliderFv2.slick({
        speed: 1500,
        fade: true,
        arrows: false,
        dots: false,
      });
      sliderFv1.slick({
        autoplay: true,
        autoplaySpeed: 3000, // 3秒ごとにスライドが変わる
        speed: 1500, // スライド切り替えの速度を1秒に設定
        fade: true,
        arrows: false,
        dots: false,
      }).on('beforeChange', function(event, slick, currentSlide, nextSlide){
        setTimeout(function(){
          sliderFv2.slick('slickNext');
        }, 1500);
      });
    }
  });
});
